import T from '../assets/T.webp';
import '../pagescss/Road.css';
import solanaLogo from '../assets/solanaLogo.png';
import metaplexlogo from '../assets/No Background - Horizontal White M + Name.png';
import Civillogo from '../assets/Logo2preview22.webp';
import TrumpMAGAlogo from '../assets/Trump_MAGA_logo_2024.svg.png';
import Visitseattlelogo from '../assets/VisitSeattle_white_Meetings.png';
import React, { useRef } from 'react';

const scrollItems = [
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' }
];

const Road = () => {
    const scrollContainerRef = useRef(null);


    const handleMouseEnter = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'paused';
        }
    };

    const handleMouseLeave = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'running';
        }
    };

    return (
        <section id="Road">
            <div className="Road-container">
                <div className="Road-phase">
                    <h3 className="phase-title">Phase 1.0: Building the Starship</h3>
                    <div className="phase-content">
                        <div className="text-content1">
                            <p><span className="percent">Mint Progress: 20%</span><span className="text">Accelerate Promotion: Launch Solana Token Bonus Campaign! Let's spread the word and fire up the engines!</span></p>
                            <p><span className="percent">Mint Progress: 50%</span><span className="text">Club Rewards Kick Off: The “Contributor Leaderboard” is live! Earn points through content creation, active promotion, and impactful project contributions. Top performers will be rewarded—time to climb the ranks!</span></p>
                            <p><span className="percent">Mint Progress: 75%</span><span className="text">Marketing Surge: The top three contributors with the most points + the two most active Club members will win iPhone 16 Pro Max (USA/CA pricing)! Let's turn up the heat and shine bright!</span></p>
                            <p><span className="percent">Mint Progress: 88%</span><span className="text">Show Off Your Collection: Choose your favorite, use it, and flaunt it in your profile! CIVIL will be the talk of the town! Plus, tweet to participate in our random 1 SOL giveaway—spread the word and make CIVIL viral!</span></p>
                            <p><span className="percent">Mint Progress: 100%</span><span className="text">Boost and Transition: Activate TikTok, Instagram, and accept creative submissions. Double down on promotion and launch the NFT Market Making System to elevate liquidity, making your assets more valuable! As liquidity grows, we'll soar into Phase 2.0!</span></p>
                        </div>
                        <div className="image-content1">
                            <img src={T} alt="Phase 1.0 Illustration" />
                        </div>
                    </div>
                </div>
                {/* Will be launch with ending of phase 1.0, fix road.js.
                <div className="Road-phase">
                    <h3 className="phase-title">Phase 2.0: Expansion Conquest</h3>
                    <div className="phase-content">
                        <div className="text-content">
                            <h6>Limited Edition Keyboard Airdrop:</h6>
                            <p>Reward top contributors from Phase 1.0 with custom mechanical keyboards, each designed with project branding—take your Degen pride to the next level.</p>
                            <h6>Mass Adoption Plan:</h6>
                            <p>Kick off a large-scale adoption campaign, partnering with multiple Web3 projects and platforms to drive mainstream NFT and crypto adoption. Host online and offline events to bring more old sports into the Web3 revolution.</p>
                            <h6>Club Airdrops & Store Launch:</h6>
                            <p>NFT and physical rewards airdrops, sharing the fruits of our growth with every club member. Launch a merch flagship store offering custom project, boosting club identity.</p>
                            <h6>FUCK the Pump:</h6>
                            <p>Activate the “God-Given Pump Tool” to drive exponential growth for our NFT project through strategic market moves and club power. We'll create demand through  incentives and partnerships, pushing the project to new heights.</p>
                            <h6>Moon Landing:</h6>
                            <p>With project expansion and growing recognition, we prepare for our “Moon Landing” , setting the stage for the next epic journey in Phase 3.0!</p>
                        </div>
                    </div>
                </div>

                <div className="Road-phase">
                    <h3 className="phase-title">Phase 3.0: Peak Governance</h3>
                    <div className="text-content">
                        <h6>Establish the Senate:</h6>
                        <p>Form a Senate of club leaders and long-term supporters to oversee the project's future, ensuring transparency and decentralized governance. Senate members will hold special voting rights and governance powers.</p>
                        <h6>Governance Token Launch:</h6>
                        <p>Roll out our governance token, empowering club members to participate in decision-making. Every Degen gets a say in shaping the project's future.</p>
                        <h6>Solana Validator:</h6>
                        <p>Set up a dedicated Solana validator node to contribute to the network's security and decentralization. This will not only reinforce our commitment to the Solana ecosystem but also provide staking opportunities and additional rewards for our club members.</p>
                        <h6>Social App Launch:</h6>
                        <p>Introduce our exclusive social app, integrating NFT display, club interaction, notifications, and trading features—providing a seamless social experience for every club member. This app will be the heartbeat of our club, connecting every Degen.</p>
                        <h6>More to Come:</h6>
                        <p>Phase 3.0 is just the beginning; more thrilling plans will be revealed as we prepare for the festivities of Phase 4.0!</p>
                    </div>
                </div>
*/}


            </div>
            <div className="divider-line"></div>
            <div
                className="scroll-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={scrollContainerRef}
            >
                <div className="scroll-content">
                    {scrollItems.map((item, index) => (
                        <a href={item.link} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={item.src} alt={item.alt} />
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Road;