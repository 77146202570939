import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import './App.css';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import BTTB from './components/BTTB';
import logo from './assets/Logo2preview22.webp';
import albumArt from './assets/musicart.webp';
import musicFile from './assets/Village People - YMCA.mp3';
import Civil from './pages/Civil';
import Mission from './pages/Mission';
import Mint from './pages/Mint';
import Road from './pages/Road';
import Team from './pages/Team';
import image1 from './assets/background.webp';
import image2 from './assets/chris.webp';
import image3 from './assets/BBQ.webp';
import image4 from './assets/sd.webp';
import MissionImage from './assets/mint.png';
import RoadImage from './assets/T.webp';
import teamMember1 from './assets/Team/Friedman.webp';
import teamMember2 from './assets/Team/Kavan.webp';
import teamMember3 from './assets/Team/Maxon.webp';
import teamMember4 from './assets/Team/Pharaoh.webp';
import oldsportGif from './assets/Team/oldsport.webp';
import CivilBG from './assets/CivilBG.webp';


const Footer = () => {
  return (
    <div className="terms">
      <a href="/Terms.html" target="_blank" rel="noopener noreferrer" className="terms-link">
        Terms & Conditions
      </a>
      <span className="copyright">©2025 CivilianismClub</span>
    </div>
  );
};

const App = () => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const imagesToLoad = [
      logo,
      CivilBG,
      albumArt,
      image1,
      image2,
      image3,
      image4,
      MissionImage,
      RoadImage,
      teamMember1,
      teamMember2,
      teamMember3,
      teamMember4,
      oldsportGif,
    ];

    let loadedImages = 0;
    const totalImages = imagesToLoad.length;

    const preloadImages = () => {
      imagesToLoad.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          loadedImages++;
          setProgress(Math.floor((loadedImages / totalImages) * 100));

          if (loadedImages === totalImages) {
            setTimeout(() => setIsLoading(false), 1500);
          }
        };
        img.onerror = () => {
          loadedImages++;
          if (loadedImages === totalImages) {
            setTimeout(() => setIsLoading(false), 1500);
          }
        };
      });
    };

    preloadImages();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (windowDimensions.width < 600 || windowDimensions.height < 300) {
    return (
      <div className="not-supported">
        <img src={logo} alt="Logo" className="logo" />
        <p>'Don't go peein' on a little ol' rock.'</p>
        <p>'God's watchin' you.'</p>
        <p>Pull up our website from your PC, thanks!</p>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        {isLoading ? (
          <div className="preloader">
            <img src={logo} alt="Loading Logo" className="preloader-logo" />
            <div className="preloader-text">WELCOME OLD SPORT!{progress}%</div>
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${progress}%` }}></div>
            </div>
          </div>
        ) : (
          <>
            <BTTB />
            <div className="background" />
            <div className="matte-effect" />
            <Navbar />
            <div className="content">
              <Routes>
                <Route path="/civil" element={<Civil />} />
                <Route path="/mission" element={<Mission />} />
                <Route path="/mint" element={<Mint />} />
                <Route path="/road" element={<Road />} />
                <Route path="/team" element={<Team />} />
                <Route path="/" element={<Civil />} />
              </Routes>
            </div>
            <div className="music-player">
              <div className="album-art-container">
                <img src={albumArt} alt="Album Art" className="album-art" />
              </div>
              <div className="music-info">
                <div className="song-title">YMCA</div>
                <div className="artist-name">@Village People</div>
              </div>
              <button onClick={togglePlayPause} className="play-pause-btn">
                <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
              </button>
              <ReactPlayer
                url={musicFile}
                playing={isPlaying}
                loop={true}
                controls={false}
                width="0"
                height="0"
                volume={0.5}
              />
            </div>

            <Footer />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;